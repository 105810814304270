import React from "react";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../components/layout";

import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
const breadcrumbsFactory = new BreadcrumbsFactory();

const StyledContainer = styled.div`
  margin-bottom: 3rem;
`;

const Article = ({pageContext}) => {
  const { title, content, slug } = pageContext;

  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'info',
      title: 'Статьи',
    },
    {
      path: `info/${slug}`,
      title,
    },
  ]);
  return (
    <Layout crumbs={ crumbs }>
    <SEO title={ title } />
      <StyledContainer className="container">
        <h1 dangerouslySetInnerHTML={{__html: title}} />
        <article dangerouslySetInnerHTML={{__html: content}}/>
      </StyledContainer>
    </Layout>
  );
};

export default Article;